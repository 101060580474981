import { lazy } from "react";

const MitraRoutes = [
  // AUTHETNTICATION
  // {
  //   path: "/phone-login",
  //   component: lazy(() => import("../../view/pages/auth/login/newlogin")),
  //   mobile: lazy(() => import("../../view/mobile/auth/login/newlogin")),
  //   layout: "FullLayout",
  //   auth: false,
  //   kyc: false,
  //   type: "h2h",
  // },
  // {
  //   path: "/auth/create-pin",
  //   component: lazy(() => import("../../view/pages/auth/pin/create")),
  //   mobile: lazy(() => import("../../view/mobile/auth/pin/create")),
  //   layout: "FullLayout",
  //   auth: false,
  //   kyc: false,
  //   type: "h2h",
  // },
  // {
  //   path: "/old-login",
  //   component: lazy(() => import("../../view/pages/auth/login")),
  //   mobile: lazy(() => import("../../view/pages/auth/login")),
  //   layout: "FullLayout",
  //   auth: false,
  //   kyc: false,
  //   type: "h2h",
  // },
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth-new/login")),
    mobile: lazy(() => import("../../view/mobile/auth-new/login")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/register",
    component: lazy(() => import("../../view/pages/auth-new/register")),
    mobile: lazy(() => import("../../view/mobile/auth-new/register")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../../view/pages/auth/recover-password")),
    mobile: lazy(() => import("../../view/pages/auth/recover-password")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../../view/pages/auth/reset-password")),
    mobile: lazy(() => import("../../view/pages/auth/reset-password")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/verify/new-user",
    component: lazy(() => import("../../view/pages/auth/verify/new")),
    mobile: lazy(() => import("../../view/pages/auth/verify/new")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/register-success",
    component: lazy(() => import("../../view/pages/auth/verify/emailVerify")),
    mobile: lazy(() => import("../../view/pages/auth/verify/emailVerify")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/verify/reset",
    component: lazy(() => import("../../view/pages/auth/verify/reset")),
    mobile: lazy(() => import("../../view/pages/auth/verify/reset")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/verified",
    component: lazy(() => import("../../view/pages/auth/verify/verified")),
    mobile: lazy(() => import("../../view/pages/auth/verify/verified")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/verify-email",
    component: lazy(() => import("../../view/pages/auth-new/verify-email")),
    mobile: lazy(() => import("../../view/mobile/auth-new/verify-email")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/verify-email-info",
    component: lazy(() => import("../../view/pages/auth-new/email-new")),
    mobile: lazy(() => import("../../view/mobile/auth-new/email-new")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/verify-phone",
    component: lazy(() => import("../../view/pages/auth-new/verify-phone")),
    mobile: lazy(() => import("../../view/mobile/auth-new/verify-phone")),
    layout: "FullLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  // MAIN PAGES
  {
    path: "/dashboard",
    exact: true,
    component: lazy(() => import("../../view/pages/dashboard")),
    mobile: lazy(() => import("../../view/mobile/dashboard")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/notification",
    exact: true,
    component: lazy(() => import("../../view/pages/notification")),
    mobile: lazy(() => import("../../view/mobile/notification")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/kyc",
    component: lazy(() => import("../../view/pages/kyc")),
    mobile: lazy(() => import("../../view/mobile/kyc")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/kyc-form",
    component: lazy(() => import("../../view/pages/kyc/form")),
    mobile: lazy(() => import("../../view/mobile/kyc/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/statistic",
    component: lazy(() => import("../../view/pages/statistic")),
    mobile: lazy(() => import("../../view/mobile/statistic")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // REFERRAL
  {
    path: "/referral/data",
    component: lazy(() => import("../../view/pages/referral/data")),
    mobile: lazy(() => import("../../view/mobile/referral/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/referral/balance-history",
    component: lazy(() => import("../../view/pages/referral/balance")),
    mobile: lazy(() => import("../../view/mobile/referral/balance")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // TRANSACTION
  {
    path: "/list-transaction",
    component: lazy(() => import("../../view/pages/transaction")),
    mobile: lazy(() => import("../../view/mobile/transaction")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/detail-transaction",
    component: lazy(() => import("../../view/pages/transaction/detail")),
    mobile: lazy(() => import("../../view/mobile/transaction/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/transaction-form",
    component: lazy(() => import("../../view/pages/transaction/newform")),
    mobile: lazy(() => import("../../view/mobile/transaction/newform")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // PRODUCT
  {
    path: "/product/data",
    component: lazy(() => import("../../view/pages/product/data")),
    mobile: lazy(() => import("../../view/mobile/product/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/product/form",
    component: lazy(() => import("../../view/pages/product/form")),
    mobile: lazy(() => import("../../view/pages/product/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // Tools
  {
    path: "/pricelist",
    component: lazy(() => import("../../view/pages/pricelist")),
    mobile: lazy(() => import("../../view/mobile/pricelist")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/best-price",
    component: lazy(() => import("../../view/pages/best-price")),
    mobile: lazy(() => import("../../view/mobile/best-price")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/price-history/data",
    component: lazy(() => import("../../view/pages/price-history/data")),
    mobile: lazy(() => import("../../view/mobile/price-history/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/price-history/detail",
    component: lazy(() => import("../../view/pages/price-history/detail")),
    mobile: lazy(() => import("../../view/mobile/price-history/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/set-selling-price/product",
    component: lazy(() => import("../../view/pages/set-selling-price/product")),
    mobile: lazy(() => import("../../view/mobile/set-selling-price/product")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/set-selling-price/data",
    component: lazy(() => import("../../view/pages/set-selling-price/data")),
    mobile: lazy(() => import("../../view/mobile/set-selling-price/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/pembukuan",
    component: lazy(() => import("../../view/pages/pembukuan")),
    mobile: lazy(() => import("../../view/mobile/pembukuan")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // TOP UP
  {
    path: "/topup/data",
    component: lazy(() => import("../../view/pages/topup/data")),
    mobile: lazy(() => import("../../view/mobile/history_balance")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/topup/form",
    component: lazy(() => import("../../view/pages/topup/form")),
    mobile: lazy(() => import("../../view/mobile/topup/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/topup/payment",
    component: lazy(() => import("../../view/pages/topup/detail")),
    mobile: lazy(() => import("../../view/mobile/topup/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/topup/invoice",
    component: lazy(() => import("../../view/pages/topup/invoice")),
    mobile: lazy(() => import("../../view/pages/topup/invoice")),
    layout: "FullLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // HISTORY BALANCE
  {
    path: "/history-balance",
    component: lazy(() => import("../../view/pages/history_balance")),
    mobile: lazy(() => import("../../view/mobile/history_balance")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // TICKET
  {
    path: "/request-ticket/data",
    component: lazy(() => import("../../view/pages/ticket/data")),
    mobile: lazy(() => import("../../view/mobile/ticket/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/request-ticket/new",
    component: lazy(() => import("../../view/pages/ticket/new")),
    mobile: lazy(() => import("../../view/mobile/ticket/new")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/request-ticket/detail",
    component: lazy(() => import("../../view/pages/ticket/detail")),
    mobile: lazy(() => import("../../view/mobile/ticket/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // FEEDBACK
  {
    path: "/feedback",
    component: lazy(() => import("../../view/pages/feedback")),
    mobile: lazy(() => import("../../view/mobile/feedback")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // ACTIVITY LOG
  {
    path: "/activity-log",
    component: lazy(() => import("../../view/pages/activity-log")),
    mobile: lazy(() => import("../../view/mobile/activity-log")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // PROFILE
  {
    path: "/profile",
    component: lazy(() => import("../../view/pages/profile")),
    mobile: lazy(() => import("../../view/mobile/profile")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/tier",
    component: lazy(() => import("../../view/pages/tier")),
    mobile: lazy(() => import("../../view/mobile/tier")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/changelog",
    component: lazy(() => import("../../view/pages/changelog")),
    mobile: lazy(() => import("../../view/mobile/changelog")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // SETTING
  {
    exact: true,
    path: "/setting",
    component: lazy(() => import("../../view/pages/setting")),
    mobile: lazy(() => import("../../view/mobile/setting")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/setting/account-setting",
    component: lazy(() => import("../../view/pages/account-setting")),
    mobile: lazy(() => import("../../view/mobile/account-setting")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/setting/reminder-saldo",
    component: lazy(() => import("../../view/pages/reminder-saldo")),
    mobile: lazy(() => import("../../view/mobile/reminder-saldo")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/setting/pin",
    component: lazy(() => import("../../view/pages/pin/data")),
    mobile: lazy(() => import("../../view/mobile/pin/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/setting/change-pin",
    component: lazy(() => import("../../view/pages/pin/form")),
    mobile: lazy(() => import("../../view/mobile/pin/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/setting/create-pin",
    component: lazy(() => import("../../view/pages/auth/pin/create")),
    mobile: lazy(() => import("../../view/mobile/auth/pin/create")),
    layout: "VerticalLayout",
    auth: false,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/setting/password",
    component: lazy(() => import("../../view/pages/change-password")),
    mobile: lazy(() => import("../../view/mobile/change-password")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/setting/api-integration",
    component: lazy(() =>
      import("../../view/pages/oldsetting/api-integrations")
    ),
    mobile: lazy(() => import("../../view/mobile/api-integration")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  // {
  //   path: "/setting/billing-information",
  //   component: lazy(() =>
  //     import("../../view/pages/oldsetting/billing-information")
  //   ),
  //   mobile: lazy(() =>
  //     import("../../view/pages/oldsetting/billing-information")
  //   ),
  //   layout: "VerticalLayout",
  //   auth: true,
  //   kyc: false,
  //   type: "h2h",
  // },
  {
    path: "/mitra-panel/status",
    component: lazy(() => import("../../view/pages/mitra-panel/status")),
    mobile: lazy(() => import("../../view/mobile/mitra-panel/status")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
  {
    path: "/mitra-panel/register",
    component: lazy(() => import("../../view/pages/mitra-panel/register")),
    mobile: lazy(() => import("../../view/mobile/mitra-panel/register")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "h2h",
  },
];

const PanelRoutes = [
  {
    path: "/panel/dashboard",
    exact: true,
    component: lazy(() => import("../../view/panel/dashboard")),
    mobile: lazy(() => import("../../view/panel/dashboard")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  // MEMBER
  {
    path: "/panel/member/data",
    exact: true,
    component: lazy(() => import("../../view/panel/member/data")),
    mobile: lazy(() => import("../../view/panel/member/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/member/detail",
    exact: true,
    component: lazy(() => import("../../view/panel/member/detail")),
    mobile: lazy(() => import("../../view/panel/member/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  // PRODUCT
  {
    path: "/panel/product-vcg/data",
    exact: true,
    component: lazy(() => import("../../view/panel/product/vcg")),
    mobile: lazy(() => import("../../view/panel/product/vcg")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/product-custom/data",
    exact: true,
    component: lazy(() => import("../../view/panel/product/manual")),
    mobile: lazy(() => import("../../view/panel/product/manual")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/product-vcg/form",
    exact: true,
    component: lazy(() => import("../../view/panel/product/form-vcg")),
    mobile: lazy(() => import("../../view/panel/product/form-vcg")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/product-custom/form",
    exact: true,
    component: lazy(() => import("../../view/panel/product/form-manual")),
    mobile: lazy(() => import("../../view/panel/product/form-manual")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  // TRANSACTION
  {
    path: "/panel/transaction/data",
    component: lazy(() => import("../../view/panel/transaction/data")),
    mobile: lazy(() => import("../../view/panel/transaction/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/transaction/detail",
    component: lazy(() => import("../../view/panel/transaction/detail")),
    mobile: lazy(() => import("../../view/panel/transaction/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/banner/data",
    exact: true,
    component: lazy(() => import("../../view/panel/banner/data")),
    mobile: lazy(() => import("../../view/panel/banner/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/banner/form",
    exact: true,
    component: lazy(() => import("../../view/panel/banner/form")),
    mobile: lazy(() => import("../../view/panel/banner/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/flash-sale/data",
    exact: true,
    component: lazy(() => import("../../view/panel/flash-sale/data")),
    mobile: lazy(() => import("../../view/panel/flash-sale/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/flash-sale/detail",
    exact: true,
    component: lazy(() => import("../../view/panel/flash-sale/detail")),
    mobile: lazy(() => import("../../view/panel/flash-sale/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/flash-sale/form",
    exact: true,
    component: lazy(() => import("../../view/panel/flash-sale/form")),
    mobile: lazy(() => import("../../view/panel/flash-sale/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/promo/data",
    exact: true,
    component: lazy(() => import("../../view/panel/promo/data")),
    mobile: lazy(() => import("../../view/panel/promo/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/promo/detail",
    exact: true,
    component: lazy(() => import("../../view/panel/promo/detail")),
    mobile: lazy(() => import("../../view/panel/promo/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/promo/form",
    exact: true,
    component: lazy(() => import("../../view/panel/promo/form")),
    mobile: lazy(() => import("../../view/panel/promo/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/category/data",
    exact: true,
    component: lazy(() => import("../../view/panel/category/data")),
    mobile: lazy(() => import("../../view/panel/category/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/category/form",
    exact: true,
    component: lazy(() => import("../../view/panel/category/form")),
    mobile: lazy(() => import("../../view/panel/category/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/category/detail",
    exact: true,
    component: lazy(() => import("../../view/panel/category/detail")),
    mobile: lazy(() => import("../../view/panel/category/detail")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/category-label/data",
    exact: true,
    component: lazy(() => import("../../view/panel/category-label/data")),
    mobile: lazy(() => import("../../view/panel/category-label/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/category-label/form",
    exact: true,
    component: lazy(() => import("../../view/panel/category-label/form")),
    mobile: lazy(() => import("../../view/panel/category-label/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/setting/payment-method/data",
    exact: true,
    component: lazy(() =>
      import("../../view/panel/setting/payment-method/data")
    ),
    mobile: lazy(() =>
      import("../../view/mobile-panel/setting/payment-method/data")
    ),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/setting/payment-method/form",
    exact: true,
    component: lazy(() =>
      import("../../view/panel/setting/payment-method/form")
    ),
    mobile: lazy(() =>
      import("../../view/mobile-panel/setting/payment-method/form")
    ),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/setting/member-tier/data",
    exact: true,
    component: lazy(() => import("../../view/panel/setting/member-tier/data")),
    mobile: lazy(() => import("../../view/panel/setting/member-tier/data")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/setting/member-tier/form",
    exact: true,
    component: lazy(() => import("../../view/panel/setting/member-tier/form")),
    mobile: lazy(() => import("../../view/panel/setting/member-tier/form")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/setting/social-media",
    exact: true,
    component: lazy(() => import("../../view/panel/setting/social-media")),
    mobile: lazy(() => import("../../view/mobile-panel/setting/social-media")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
  {
    path: "/panel/setting/web-panel",
    exact: true,
    component: lazy(() => import("../../view/panel/setting/web-panel")),
    mobile: lazy(() => import("../../view/mobile-panel/setting/web-panel")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    type: "panel",
  },
];
const PagesRoutes = [...MitraRoutes, ...PanelRoutes];

export default PagesRoutes;
