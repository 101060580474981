export const api_base_url = process.env.REACT_APP_BASE_URL;
export const api_websocket_url = process.env.REACT_APP_WEBSOCKET_URL + "/ws";

export const API_URL = {
  register: api_base_url + "/v1/crm/register",
  registerMitra: api_base_url + "/v1/crm/register-mitra",
  verifyPhone: api_base_url + "/v1/crm/set-phone-confirmation",
  verifyEmail: api_base_url + "/v1/crm/set-email-confirmation",
  checkReferral: api_base_url + "/v1/crm/check-referral-code",
  confirm_email: api_base_url + "/v1/crm/confirm",
  send_email: api_base_url + "/v1/crm/verification/resend-email",
  login: api_base_url + "/v1/crm/login",
  forgot: api_base_url + "/v1/crm/send-reset-password-email",
  new_password: api_base_url + "/v1/crm/submit-reset-password",

  // New Auth
  auth_check: api_base_url + "/v1/crm/check-auth",
  login_phone: api_base_url + "/v1/crm/login-v2",
  noauth_otp: api_base_url + "/v1/crm/otp/request-by-phone",
  noauth_confirm: api_base_url + "/v1/crm/otp/confirm-by-phone",

  otp: api_base_url + "/v1/crm/otp",
  onboarding: api_base_url + "/v1/crm/onboarding",

  referral_partner: api_base_url + "/v1/crm/referral/partner",
  referral_balance: api_base_url + "/v1/crm/referral/balance-history",
  referral_withdrawal: api_base_url + "/v1/crm/referral/withdraw",

  get_product: api_base_url + "/v1/crm/product/all",
  get_newproduct: api_base_url + "/v1/crm/product/parent",
  get_product_with_tag: api_base_url + "/v1/crm/product-tag/parent",
  export_newproduct: api_base_url + "/v1/crm/best-product/export_csv",
  detail_product: api_base_url + "/v1/crm/product/detail",
  update_product: api_base_url + "/v1/crm/product",
  export_product: api_base_url + "/v1/crm/product/export_csv",
  top_product: api_base_url + "/v1/crm/top-products",
  price_history: api_base_url + "/v1/crm/product/price-history",

  pembukuan: api_base_url + "/v1/crm/entries",

  get_category: api_base_url + "/v1/crm/category/all",
  detail_product: api_base_url + "/v1/crm/product/detail",
  option_category: api_base_url + "/v1/crm/category/option",
  parent_category: api_base_url + "/v1/crm/category/parent",
  label_category: api_base_url + "/v1/crm/category/labels",

  get_transaction: api_base_url + "/v1/crm/transaction/all",
  create_transaction: api_base_url + "/v1/crm/transaction/create",
  new_transaction: api_base_url + "/v1/crm/transaction/create-new",
  detail_transaction: api_base_url + "/v1/crm/transaction/detail",
  export_transaction: api_base_url + "/v1/crm/transaction/export_csv",

  get_history_balance: api_base_url + "/v1/crm/balance_history/all",
  export_history_balance: api_base_url + "/v1/crm/balance_history/export_csv",

  dashboard_total_transaction:
    api_base_url + "/v1/crm/dashboard/total_transaction",
  dashboard_balance: api_base_url + "/v1/crm/dashboard/balance",
  dashboard_partner: api_base_url + "/v1/crm/referral/partner",
  dashboard_sales_overview:
    api_base_url + "/v1/crm/dashboard/transaction-chart",
  dashboard_product_chart: api_base_url + "/v1/crm/statistic/products-chart",

  statistic: api_base_url + "/v1/crm/statistic",

  ticket: api_base_url + "/v1/crm/ticket-system",
  feedback: api_base_url + "/v1/crm/feedback",
  activity_log: api_base_url + "/v1/crm/activity-log",
  billing_info: api_base_url + "/v1/crm/billing-information",
  access_key: api_base_url + "/v1/crm/setting/access-token",
  secret_key: api_base_url + "/v1/crm/setting/access-token/secret",
  ip_whitelist: api_base_url + "/v1/crm/setting/ip-whitelist",
  top_up: api_base_url + "/v1/crm/topup",
  topUp_nominal: api_base_url + "/v1/crm/topup/nominals",
  payment_method: api_base_url + "/v1/crm/payment-methods",
  manual_payment_method: api_base_url + "/v1/crm/manual-payment-methods",
  region: api_base_url + "/v1/region",
  complete_kyc: api_base_url + "/v1/crm/complete-kyc",
  uploader: api_base_url + "/uploader/new",
  delete_s3: api_base_url + "/uploader/delete",
  webhooks: api_base_url + "/v1/crm/setting/webhook",
  profile: api_base_url + "/v1/crm/profile",
  update_profile: api_base_url + "/v1/crm/setting",
  update_password: api_base_url + "/v1/crm/update-password",
  notification: api_base_url + "/v1/crm/notifications",
  pin_status: api_base_url + "/v1/crm/setting/update-pin-status",
  verify_pin: api_base_url + "/v1/crm/verify-pin",
  new_pin: api_base_url + "/v1/crm/setting/new-pin",
  update_pin: api_base_url + "/v1/crm/setting/update-pin",
  tier: api_base_url + "/v1/crm/tier-list",
  changelog: api_base_url + "/v1/crm/changelogs",
  panel: api_base_url + "/v1/crm/panel",
};

export const API_URL_PANEL = {
  theme: api_base_url + "/v1/vendor-panel/theme-list",
  color: api_base_url + "/v1/vendor-panel/theme-color-list",
  statistic: api_base_url + "/v1/vendor-panel/dashboard",
  member: api_base_url + "/v1/vendor-panel/member",
  product: api_base_url + "/v1/vendor-panel/product",
  banner: api_base_url + "/v1/vendor-panel/banner",
  flashSale: api_base_url + "/v1/vendor-panel/flash-sale",
  promo: api_base_url + "/v1/vendor-panel/promotion",
  product: api_base_url + "/v1/vendor-panel/product",
  tier: api_base_url + "/v1/vendor-panel/tier",
  category: api_base_url + "/v1/vendor-panel/category",
  categoryLabel: api_base_url + "/v1/vendor-panel/category-label",
  transaction: api_base_url + "/v1/vendor-panel/transaction",
  balanceHistory: api_base_url + "/v1/vendor-panel/member/balance-histories",
  socialMedia: api_base_url + "/v1/vendor-panel/social-media",
  paymentMethod: api_base_url + "/v1/vendor-panel/payment-method",
  webPanel: api_base_url + "/v1/vendor-panel/profile",
};

export const PLUGIN_API_URL = {
  check_user: api_base_url + "/v1/crm/transaction/check-user-id",
  check_user_new: api_base_url + "/v1/crm/transaction/check-user-id-new",
};

export const WEBSOCKET_API_URL = {
  notification: api_websocket_url + "/mitra",
  balance: api_websocket_url + "/mitra-balance",
};
