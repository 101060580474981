import React from "react";
import { Link } from "react-router-dom";

import { Divider, Row, Col } from "antd";

import MitraArea from "../../../../assets/images/icons/mitra.svg";
import MitraAreaIcon from "../../../../assets/images/icons/mitra-icon.svg";
import PanelArea from "../../../../assets/images/icons/panel.svg";
import PanelAreaIcon from "../../../../assets/images/icons/panel-icon.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function MenuFooter(props) {
  const location = useLocation();
  const sidebarType = localStorage.getItem("vcg-sidebar-type") ?? "mitra";
  const handleSwitchMode = (mode) => {
    if (mode === "panel") {
      window.location.href = "/panel/dashboard";
      localStorage.setItem("vcg-sidebar-type", "panel");
    } else {
      window.location.href = "/dashboard";
      localStorage.setItem("vcg-sidebar-type", "mitra");
    }
  };

  return props.collapsed === false ? (
    <Row
      className="vc-sidebar-footer vc-pb-24 vc-px-24 vc-bg-color-dark-100"
      align="middle"
      justify="space-between"
    >
      <Divider className="vc-border-color-black-20 vc-border-color-dark-70 vc-mt-0" />

      <Col span={24}>
        {location.pathname.startsWith("/panel") ? (
          <div
            className="vc-border-1 vc-border-color-orange-1 vc-p-8 vc-d-flex vc-d-flex-between vc-bg-color-orange-4 vc-align-items-center"
            style={{ borderRadius: "8px" }}
          >
            <div
              className="vc-text-color-orange-1"
              style={{ fontSize: "12px" }}
            >
              {" "}
              Kamu berada di Panel Area
            </div>
            <div
              className="vc-text-color-orange-1 vc-cursor-pointer"
              onClick={() => handleSwitchMode("mitra")}
              style={{ fontWeight: 600, fontSize: "12px" }}
            >
              {" "}
              Switch
            </div>
          </div>
        ) : (
          <div
            className="vc-border-1 vc-border-color-primary-1 vc-p-8 vc-d-flex vc-d-flex-between vc-bg-color-primary-4 vc-align-items-center"
            style={{ borderRadius: "8px" }}
          >
            <div
              className="vc-text-color-primary-1"
              style={{ fontSize: "12px" }}
            >
              {" "}
              Kamu berada di Mitra Area
            </div>
            <div
              className="vc-text-color-primary-1 vc-cursor-pointer"
              onClick={() => handleSwitchMode("panel")}
              style={{ fontWeight: 600, fontSize: "12px" }}
            >
              {" "}
              Switch
            </div>
          </div>
        )}
      </Col>
    </Row>
  ) : (
    <Row
      className="vc-sidebar-footer vc-pt-16 vc-mb-16 vc-bg-color-dark-100"
      align="middle"
      justify="center"
    >
      <Col>
        {location.pathname.startsWith("/panel") ? (
          <div
            className="vc-border-1 vc-border-color-orange-1 vc-p-8 vc-d-flex vc-d-flex-between vc-bg-color-orange-4 vc-align-items-center"
            style={{ borderRadius: "8px" }}
          >
            <div
              className="vc-text-color-orange-1"
              style={{ fontSize: "12px" }}
            >
              {" "}
              Kamu berada di Panel Area
            </div>
            <div
              className="vc-text-color-orange-1 vc-cursor-pointer"
              onClick={() => handleSwitchMode("mitra")}
              style={{ fontWeight: 600, fontSize: "12px" }}
            >
              {" "}
              Switch
            </div>
          </div>
        ) : (
          <div
            className="vc-border-1 vc-border-color-primary-1 vc-p-8 vc-d-flex vc-d-flex-between vc-bg-color-primary-4 vc-align-items-center"
            style={{ borderRadius: "8px" }}
          >
            <div
              className="vc-text-color-primary-1"
              style={{ fontSize: "12px" }}
            >
              {" "}
              Kamu berada di Mitra Area
            </div>
            <div
              className="vc-text-color-primary-1 vc-cursor-pointer"
              onClick={() => handleSwitchMode("panel")}
              style={{ fontWeight: 600, fontSize: "12px" }}
            >
              {" "}
              Switch
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
}
