import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col, Tag, Tooltip, Image, Skeleton } from "antd";
import {
  RiMenuFill,
  RiCheckboxBlankCircleFill,
  RiInformationLine,
  RiErrorWarningLine,
  RiCloseLine,
} from "react-icons/ri";

import HeaderUser from "./HeaderUser";
import HeaderText from "./HeaderText";
import HeaderTheme from "./HeaderTheme";
import { getAll } from "../../../utils/service";
import { API_URL } from "../../../api/config";
import { formatterNumber } from "../../../utils/input-number";
import { useHistory } from "react-router-dom";
import HeaderNotifications from "./HeaderNotifications";
import { useWebSocketBalance } from "../../../WebSocketContext";
import { useGetProfile } from "../../../UserProfileContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HeaderSearch from "./HeaderSearch";
import { Search } from "react-iconly";

const { Header } = Layout;

export default function MenuHeader(props) {
  const history = useHistory();
  const { setVisible } = props;

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true);

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: "start",
      });
    }, 200);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
  };

  const dataProfile = useGetProfile();

  const webSocketBalance = useWebSocketBalance();
  const [userBalance, setUserBalance] = useState(0);

  const getUserBalance = () => {
    setUserBalance(webSocketBalance || dataProfile?.saldo);
  };

  useEffect(() => {
    getUserBalance();
  }, [webSocketBalance]);

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="vc-w-100 vc-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="vc-mobile-sidebar-button vc-mr-24">
          <Button
            className="vc-mobile-sidebar-button"
            type="text"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon vc-text-color-black-80 vc-text-color-dark-30"
              />
            }
          />
        </Col>

        <Col
          flex="1"
          style={{ display: !searchHeader ? "none" : "block" }}
          className={`vc-pr-md-0 vc-pr-16 vc-header-search ${
            searchActive && "vc-header-search-active"
          }`}
        >
          <HeaderSearch
            inputFocusProp={inputFocusProp}
            setSearchHeader={setSearchHeader}
          />
        </Col>

        {!searchHeader && (
          <Col flex={"auto"}>
            <HeaderText name={dataProfile?.name} />{" "}
          </Col>
        )}

        <Col>
          <Row align="middle">
            {!searchHeader && (
              <>
                <Col className="vc-d-flex-center vc-mr-8 vc-d-sm-none">
                  <Link to="/tier">
                    <span className="vc-font-weight-600" id="vc-tier-component">
                      <Tooltip
                        className="vc-ml-4"
                        placement="bottom"
                        title="Tier ini akan di sesuaikan secara otomatis bedasarkan nilai topup deposit saldo verifikasi KYC Akun."
                      >
                        {dataProfile?.tier_image === "" ||
                        dataProfile?.tier_image === null ? (
                          <Image
                            id="response-tier-image"
                            src={
                              "https://cdn-h2h.s3.ap-southeast-1.amazonaws.com/h2h/20231015061450_652b835a31f9e42905b64128.webp"
                            }
                            width={24}
                            height={24}
                            preview={false}
                          />
                        ) : (
                          <Image
                            id="response-tier-image"
                            src={dataProfile?.tier_image}
                            width={24}
                            height={24}
                            preview={false}
                          />
                        )}
                        <span className="vc-ml-8 vc-text-color-dark-10">
                          {dataProfile?.tier_name}
                        </span>
                      </Tooltip>
                    </span>
                  </Link>
                </Col>

                <Col className="vc-d-flex-center vc-mr-8 vc-text-color-black-60 vc-d-sm-none">
                  <RiCheckboxBlankCircleFill style={{ fontSize: "4px" }} />
                </Col>
                <Col className="vc-d-flex-center vc-mr-8">
                  <span
                    className="vc-text-color-primary-1 vc-font-weight-600"
                    id="vc-amount-component"
                  >
                    <span className="vc-d-sm-none">Saldo : </span>
                    {userBalance ? (
                      <a href="/topup/data">
                        Rp {formatterNumber(userBalance)}
                      </a>
                    ) : (
                      <i>Sedang memuat</i>
                    )}
                    {dataProfile?.kyc_verified === false &&
                      dataProfile?.maximum_unverified_amount !== 0 && (
                        <Tooltip
                          className="vc-ml-4"
                          title={
                            "Limit Rp " +
                            formatterNumber(
                              dataProfile?.maximum_unverified_amount
                            ) +
                            ", Silahkan lengkapi berkas"
                          }
                        >
                          <RiErrorWarningLine className="vc-text-color-danger-1" />
                        </Tooltip>
                      )}
                  </span>
                </Col>
              </>
            )}

            <Col className="vc-mr-sm-0 vc-mr-4 vc-d-flex-center">
              {!searchHeader ? (
                <Button
                  type="text"
                  icon={
                    <Search set="curved" className="vc-text-color-black-60" />
                  }
                  onClick={() => searchClick()}
                />
              ) : (
                <Button
                  type="text"
                  icon={
                    <RiCloseLine size={24} className="vc-text-color-black-60" />
                  }
                  onClick={() => setSearchHeader(false)}
                />
              )}
            </Col>

            <HeaderNotifications />

            <Col className="vc-d-flex-center vc-mr-4 ">
              <HeaderTheme />
            </Col>

            <HeaderUser
              name={dataProfile?.name}
              tier={dataProfile?.tier}
              panel={dataProfile?.panel}
              panelStatus={dataProfile?.tier_has_panel}
            />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Header>
      <Row justify="center" className="vc-w-100">
        {customise.contentWidth == "full" && (
          <Col span={24}>{headerChildren()}</Col>
        )}

        {customise.contentWidth == "boxed" && (
          <Col xxl={20} xl={22} span={24}>
            {headerChildren()}
          </Col>
        )}
      </Row>
    </Header>
  );
}
